import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import CssHeader from "../components/typography/css-header"

export default function BlogPost({ data }) {
  const post = data.markdownRemark
  return (
    <Layout>
      <SEO title={post.frontmatter.title} />
      <div>
        <CssHeader>{post.frontmatter.title}</CssHeader>
        <div dangerouslySetInnerHTML={{ __html: post.html }} className="prose prose-invert p-6 max-w-5xl mx-auto bg-css-content rounded-md"/>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      tableOfContents(
        maxDepth: 3
      )
      frontmatter {
        title
      }
    }
  }
`